@charset "utf-8";
/* CSS Document */

body { font-family:"微软雅黑"; margin:0; padding:0; background:#ffffff;}
div,form,img,ul,ol,li,dl,dt,dd,p,h1,h2,h3,h4,h5,h6,img{ margin:0; padding:0; border:0; font-weight:normal; font-size:12px;}
img{ border:none; vertical-align:middle;}
ul,li{ list-style-type: none; }
button{ vertical-align:middle; cursor:pointer;}
input, button{	vertical-align:middle; border:none; font-family:"微软雅黑";}
.clear { clear:both; overflow:hidden; height:0px;}
.blank{ display:block; height:10px; clear:both; overflow:hidden; visibility:hidden;}
.lft{ float:left;}
.rgt{ float:right;}
:focus {outline: none;}
.clearfix:after{content:"." ;display: block;height: 0;clear:both;visibility: hidden;}
.clearfix{ zoom:1;}
body{overflow-x:hidden}.loading{position:fixed;top:0;left:0;z-index:99999;width:100%;height:100%;background:#fff}.loadingfa{position:absolute;top:50%;left:50%;margin-top:-25px;margin-left:-25px;width:50px;height:50px;color:#2222cd;text-align:center;font-size:29px;line-height:50px}@keyframes rotate{0%{-webkit-transform:rotate(0deg)}to{-webkit-transform:rotate(360deg)}}@-webkit-keyframes rotate{0%{-webkit-transform:rotate(0deg)}to{-webkit-transform:rotate(360deg)}}.xuanzhuan{animation:2s linear 0s normal forwards infinite rotate;-webkit-animation:2s linear 0s normal forwards infinite rotate;-moz-animation:2s linear 0s normal forwards infinite rotate;-o-animation:2s linear 0s normal forwards infinite rotate}

.flex{
	display: flex;
}
.flex-c{
	display: flex;
    justify-content: center;
    align-items: center;
}
.flex-Lc{
	display: flex;
    align-items: center;
}
.flex-column{
	display: flex;
  	flex-direction: column;
  	justify-content: space-between;
}
.flex-L{
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.flex-Lt{
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.flex-Lend{
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-moz-box-align: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.flex-end{
	display: flex;
	justify-content: center;
	align-items: flex-end;
}