@charset "utf-8";

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: #000;
    text-decoration: none;
}

img {
    max-width: 100%;
}

body {
    max-width: 750px;
    margin: 0 auto;
    background-color: #f7f7f7;
    margin-top: 0.85rem;
}
input[type='text'],
input[type='submit'],
input[type='button'] {
    -webkit-appearance: none; /*去除iPhone input默认样式}*/
}
input,
textarea {
    outline: none;
    background: none;
}
select {
    color: #ffffff !important;
    border: 0px solid #ffffff;
    border: 0 none;
    border-radius: 0;
    /*   outline: none;
    background: none;
    appearance: none;
    -webkit-appearance: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0.5); */
}

select:focus {
    border: 0px;
    box-shadow: 0 0 0px 0px #fff;
}

.header-ss {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.3rem 0 0.2rem 0.2rem;
    position: relative;
    z-index: 1024;
}
.header-ss a img {
    width: 0.22rem;
    margin-right: 0.2rem;
}
.header-ss span {
    font-size: 0.32rem;
    color: #000000;
}

/**app.wxss**/
.warp {
    width: 100%;
    overflow: hidden;
}

.f7 {
    background-color: #f7f7f7;
}

.c9 {
    color: #999999;
}

.tabbar {
    width: 100%;
    display: flex;
    padding: 0.2rem 0;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
}

.tabbar > a {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.24rem;
    color: #999999;
    line-height: 0.22rem;
}

.tabbar > a img {
    width: 0.32rem;
    margin-bottom: 0.13rem;
}

.tabbar > a.active {
    color: #f06235;
}

input[type='radio'] {
    width: 0.32rem;
    height: 0.32rem;
    border-radius: 50%;
    background-color: rgba(238, 238, 238, 1);
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}
input[type='radio']:checked {
    background-image: url(../images/Select.png);
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}

.getDateBox {
    height: 4rem;
}

.getDateBox .choiceDateTitle {
    height: 0.8rem !important;
}

.getDateBox .choiceDateTitle button {
    padding: 0 0.3rem !important;
    font-size: 0.26rem !important;
}

.getDateBox .dateContent {
    margin: 0.12rem 0 !important;
    height: 2.3rem !important;
}

#yearwrapper ul li,
#monthwrapper ul li,
#daywrapper ul li {
    font-size: 0.28rem !important;
}

header.divid-top {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #f7f7f7;
    z-index: 1024;
}
header.divid-top-a {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: rgba(255, 107, 70, 1);
    z-index: 1024;
}

header.divid-top-a-v {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: rgb(240, 98, 53, 1);
    z-index: 1024;
}
