.my-swal {
    font-size: 0.32rem;

    .swal2-html-container {
        font-size: 0.32rem;
    }

    .swal2-popup {
        width: 7rem;
        padding: 0 0 0.5rem;
    }
}

.invalid-feedback {
    color: #f06235;
    padding-left: 5px;
    padding-right: 5px;
}

.form {
    margin-top: 1rem;
    padding: 0 0.2rem;
}

.form .phone,
.form .password {
    width: 100%;
    height: 0.86rem;
    background: #ffffff;
    border-radius: 0.1rem;
    margin-bottom: 0.19rem;
    display: flex;
    align-items: center;
}

.form span {
    width: 0.95rem;
    display: block;
    text-align: center;
    font-size: 0.26rem;
    color: #222222;
    line-height: 0.22rem;
}

.form input {
    flex: 1;
    height: 0.86rem;
    border-left: 0.01rem solid #eeeeee;
    padding-left: 0.16rem;
    font-size: 0.26rem;
    color: #222222;
    line-height: 0.22rem;
}

.form .login {
    width: 100%;
    height: 0.86rem;
    background: #f06235;
    border-radius: 0.1rem;
    line-height: 0.86rem;
    border: none;
    margin-top: 0.31rem;
    font-size: 0.28rem;
    color: #ffffff;
}

.jump_login {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.28rem;
    color: #666666;
}

.jump_login span {
    color: #f06235;
}

.forget-password {
    margin-top: 0.25rem;
    text-align: center;
    font-size: 0.28rem;
    color: #666666;
}

.forget-password span {
    color: #f06235;
}
